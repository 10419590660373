body {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  font-size: calc(10px + 2vmin);
  color: white;
}

nav {
  margin: 5px;
}

article {
  background-color: #efefef;
  text-align: justify;  
  font-size: medium;
  color: black;
  padding: 20px;
}

footer {
  text-align: center;  
  font-size: small;
  color: gray;
  padding: 20px;
  line-height: 10pt;
}

a {
  text-decoration:none;
  color: #4069b6;
}

a:hover {
  text-decoration:underline;
  color: #1e4691;
}

.app-link {
  color: #61dafb;
}

.app-link:hover {
  color: #61dafb;
}

.current-page-link {
  color: #14272c;
  background-color: #b2e8f7;
  text-decoration: dashed;
}

.wrapper {
  width: 80vw;
  min-width:400;
  max-width: 1024;
  text-align:center;
}

.adUnit {
  background-color: #efefef;
  text-align: justify;  
  font-size: medium;
  color: black;
  padding: 20px;    
}

#bottomNav {
  background-color: #efefef;
  text-align: right;  
  font-size: medium;
  color: black;
  padding: 20px;
}

#scrollBar {
  background: transparent;
  height: 10px;
  width: 100%;
  opacity: 50;
  z-index: 999;
  position: relative;
}


/* Smartphones ----------- */
@media only screen and (max-width: 760px) {

  .wrapper {
    text-align:center;
    width: 100vw;
  }

}

/* Print mode ----------- */

@media print {

  body {
    background-color: white;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: top;
    font-size: 12pt;
    color: black;
  }

  footer {
    text-align: center;  
    font-size: small;
    color: gray;
    line-height: 10pt;
  }

  .wrapper {
    width: 100vw;
    text-align:center;
  }

  .app-link {
    color: black;
    text-decoration: none;
  }

  .current-page-link {
    color: #14272c;
    background-color: #b2e8f7;
    text-decoration: dashed;
  }

  .adUnit {
    display: none;
  }
}